<template>
  <div class="fj_orderlist">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="16">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品订单</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="8">
          <el-tag :key="tag.iType" v-for="tag in statesdata">{{ tag.label }}:{{ tag.value }}</el-tag>
        </el-col>
      </el-row>
    </div>
    <el-container>
      <!--分类-->
      <el-aside width="80px">
        <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" size="mini" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="orderno" placeholder="单号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="collectphone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="nickname" placeholder="下单用户"></el-input>
            </el-form-item>

            <el-form-item label="订单类型">
              <el-select v-model="ordertypeid" placeholder="请选择" clearable>
                <el-option v-for="item in ordertypedata" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="创建时间段">
              <el-date-picker v-model="paytime" type="daterange" @change="paytimechange" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onCurrentChange(1)">查询</el-button>
              <el-button type="primary" @click="rexport()">导出</el-button>
              <!-- <el-button type="primary" v-if="typeId == 1" @click="getmd()">获取面单</el-button> -->
              <!-- <el-button type="primary" @click="gobatchprint()">批量打印面单</el-button> -->
              <el-button type="primary" @click="getships()">批量打印发货单</el-button>
              <!-- <el-button type="primary" @click="getplfh()">批量发货</el-button> -->
            </el-form-item>
          </el-form>
          <el-table :data="adminList" border="" size="mini" :height="tabHeight" style="width: 100%" ref="multipleTable"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="orderno" label="订单信息" width="245">

              <template slot-scope="scope">

                <p>订单号： <el-button size="mini" @click="godetail(scope.row.orderno)">{{ scope.row.orderno }}</el-button>
                </p>
                <p>下单用户：{{ scope.row.nickname }}</p>
                <p>会员ID:{{ scope.row.userid }}</p>

              </template>



            </el-table-column>
            <el-table-column prop="totalpaymoney" label="支付信息" width="255">
              <template slot-scope="scope">

                <p>商品金额：{{ scope.row.totalmoney }}元</p>
                <p v-if="scope.row.paytype == 4">实际支付：{{ scope.row.totalpaymoney }}元</p>
                <p v-if="scope.row.paytype == 5">实际支付：{{ scope.row.payintegral }}积分</p>


                <el-tag>{{ ["", "", "线下到付", "余额支付", '微信支付', '积分支付', '免支付'][scope.row.paytype] }}</el-tag>
                <el-tag>{{ ["未付款", "已付款", "免支付", '已退款'][scope.row.ispay] }}</el-tag>
                <el-tag>{{ ['普通订单', '积分兑换订单', '礼包订单'][scope.row.ordertype] }}</el-tag>
              </template>

            </el-table-column>

            <el-table-column prop="totalpaymoney" label="订单状态" align="center">
              <template slot-scope="scope">{{ ["待付款", "待发货", "已发货", "已完成", '已取消', '', '售后中'][scope.row.states]
              }}

              </template>
            </el-table-column>

            <el-table-column prop="expressName" label="快递信息" align="center"> <template slot-scope="scope">
                <p>快递公司：{{ scope.row.expressName }}</p>
                <p>快递单号：{{ scope.row.expressnum }}</p>
                <p>打印面单： <el-link type="primary" target="_blank" :href="scope.row.templateurl">{{ scope.row.templateurl
                }}
                  </el-link>
                </p>
              </template>
            </el-table-column>


            <el-table-column prop="addtime" label="创建时间" align="center"></el-table-column>

            <el-table-column prop="userremarks" label="用户备注" align="center"></el-table-column>
            <el-table-column label="操作" fixed="right" width="320" align="center">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="gorefund(scope.row.orderno)"
                  v-if="scope.row.ispay == 1 && scope.row.refundauditstates == 0">退款</el-button>
                <el-button type="danger" v-if="scope.row.states == 1" size="mini"
                  @click="deliveryexpress(scope.row.orderno)">发货</el-button>

                <el-button type="danger" v-if="scope.row.states == 2" size="mini"
                  @click="editexpress(scope.row.orderno)">修改快递</el-button>
                <el-button size="mini" v-if="scope.row.states == 2" @click="setStates(scope.row.id, 3, scope.row)">完成
                </el-button>

                <el-button size="mini" v-if="scope.row.states == 1" @click="btnprint2022(scope.row.id)">打印发货单
                </el-button>
                <el-button size="mini" @click="printorder(scope.row.id)">打印小票</el-button>

                <el-button size="mini" @click="godetail(scope.row.orderno)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination background="" layout="total,prev, pager, next" :page-size="10" :current-page="pageIndex"
            :total="pageTotal" @current-change="onCurrentChange" />
        </div>
      </el-main>
    </el-container>
    <el-dialog title="批量获取电子面单" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="快递公司">
          <el-select v-model="expressid" filterable allow-create default-first-option placeholder="请选择">
            <el-option v-for="item in expressdata" :key="item.id" :label="item.exname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="svaemd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListRequest,
  paymentRequest,
  setStatesRequest,
  setpayRequest,
  exportOrderRequest,
  refundorderRequest,
  ljerp,
  tberp,
  getexpressmd,
  getBatchshipment, printorder
} from '@/api/fj_order'
import { getselectExpress } from '@/api/sys_Expressdelivery'

import returnaddressVue from '../home/returnaddress.vue'
export default {
  name: 'fj_orderlist',

  data() {
    return {
      dialogFormVisible: false,
      tabHeight: window.innerHeight - 300,
      id: 0,
      orderno: '',
      nickname: '',
      userphone: '',
      giftenddate: [],
      giftenddatestr: '',
      paytime: [],
      paytimestr: '',
      shopname: '',
      iType: -1,
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      collectphone: '',
      jumsg: '',
      typeId: -1,
      ordertypeid: -1,
      ordertypedata: [{
        label: '全部',
        value: -1
      },
      {
        label: '正常订单',
        value: 0
      },

      {
        label: '积分订单',
        value: 1
      }],
      treedata: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '未付款',
          value: 0
        },

        {
          label: '待发货',
          value: 1
        },

        {
          label: '已发货',
          value: 2
        },

        {
          label: '已完成',
          value: 3
        },

        {
          label: '已取消',
          value: 4
        },

      ],
      expressdata: [],
      multipleSelection: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dfrow: {},
      expressid: '',
      //  var LODOP, P_ID = "", TaskID1, TaskID2, t, c = 0;//声明为全局变量 ; //声明为全局变量
      lodlopdata: {},
      LODOP: {},
      P_ID: '',
      TaskID1: '',
      TaskID2: '',
      t: 0,
      c: 0,
      statesdata: [],
      orderids: ''
    }
  },

  watch: {
    $route: {
      handler(to, from) {
        this.typeId = to.query.typeId || -1

        this.onCurrentChange(1)
      },
      immediate: true
    }
  },

  created() {
    this.getselectExpress()
    this.getList()
  },
  methods: {
    goaudit(id) {
      this.$router.push({
        path: '/refundorder/auditorder',
        query: { id: id }
      })
    },
    //批量发货
    getplfh() {
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择获取面单的待发货订单')
        return
      }
      const templateurlList = this.multipleSelection.filter(
        item => !item.templateurl
      )
      if (templateurlList.length) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `
            ${templateurlList
              .map(item => {
                return `<p>订单号：${item.orderno}</p>`
              })
              .join('')}

            <p>未获取面单，请选择已获取面单的订单</p>
            `
        })
        return
      }

      this.$confirm(
        `是否确认批量发货处理${this.multipleSelection.length}条订单信息`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          getBatchshipment({
            ids: this.orderids
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    //批量打印
    gobatchprint() {
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择打印面单订单')
        return
      }
      const templateurlList = this.multipleSelection.filter(
        item => !item.templateurl
      )
      if (templateurlList.length) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `
            ${templateurlList
              .map(item => {
                return `<p>订单号：${item.orderno}</p>`
              })
              .join('')}

            <p>未获取面单，请选择已获取面单的订单</p>
            `
        })
        return
      }

      this.$confirm(
        `是否确认打印${this.multipleSelection.length}条订单信息`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.btnprint_batch()
        })
        .catch(() => { })
    },

    //批量打印发货单
    getships() {
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择待发货订单')
        return
      }
      const templateurlList = this.multipleSelection.filter(
        item => item.states != 1
      )

      this.smghtmlpath =
        'http://json.snowbarbi.dh100.net/Home/orderbatchship?ids=' +
        this.orderids +
        '&r=' +
        Math.round(Math.random() * 500)

      window.open(this.smghtmlpath)
    },

    shipprint(ids) {
      this.smghtmlpath =
        'http://json.snowbarbi.dh100.net/Home/ordership?ids=' +
        ids +
        '&r=' +
        Math.round(Math.random() * 500)

      window.open(this.smghtmlpath)
    },
    btnprint_batch() {
      this.multipleSelection.forEach(item => {
        if (item.templateurl == '') {
          this.$message.error(
            item.orderno + '未获取面单，请选择已获取面单的订单'
          )
          return
        }
      })

      this.smghtmlpath =
        'http://json.snowbarbi.dh100.net/Home/orderbatchprint?ids=' +
        this.orderids +
        '&r=' +
        Math.round(Math.random() * 500)

      window.open(this.smghtmlpath)
    },
    btnprint2022(id) {


      this.smghtmlpath =
        'http://json.snowbarbi.dh100.net/Home/ordership?t=1&ids=' +
        id +
        '&r=' +
        Math.round(Math.random() * 500)

      window.open(this.smghtmlpath)
    },
    getselectExpress() {
      getselectExpress().then(res => {
        console.log(res)
        this.expressdata = res.data
      })
    },
    handleSelectionChange(val) {
      console.info(val)
      this.multipleSelection = val
      this.orderids = this.multipleSelection
        .map(m => {
          return m.id
        })
        .join(',')
      console.info(this.multipleSelection)
    },
    getmd() {//获取面单
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择获取面单的订单')
        return
      }
      this.dialogFormVisible = true
    },
    svaemd() {
      if (!this.expressid) {
        this.$message.error('请选择快递')
        return
      }

      this.$confirm('是否确认获取面单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getexpressmd({
            ids: this.orderids,
            expressid: this.expressid
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    getList() {
      getListRequest({
        orderno: this.orderno,
        ordertypeid: this.ordertypeid,
        nickname: this.nickname,
        userphone: this.userphone,
        giftenddate: this.giftenddatestr,
        paytime: this.paytimestr,
        shopname: this.shopname,
        collectphone: this.collectphone,
        states: this.typeId,
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        console.info(res)
        let data = res.data.datalist

        this.adminList = data.data
        this.pageTotal = data.count

        this.statesdata = res.data.statesdat
        console.info(this.statesdata)
      })
    },
    rexport() {
      //导出
      exportOrderRequest({
        orderno: this.orderno,
        nickname: this.nickname,
        userphone: this.userphone,
        giftenddate: this.giftenddatestr,
        collectphone: this.collectphone,
        paytime: this.paytimestr,
        shopname: this.shopname,
        states: this.typeId
      }).then(res => {
        let blob = new Blob([res], {
          type: 'application/octet-stream'
        }) // 转化为blob对象
        console.log(blob)
        let filename = '订单.xlsx' // 判断是否使用默认文件名
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var blobURL = window.URL.createObjectURL(blob) // 将blob对象转为一个URL
          var tempLink = document.createElement('a') // 创建一个a标签
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', filename) // 给a标签添加下载属性
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          document.body.appendChild(tempLink) // 将a标签添加到body当中
          tempLink.click() // 启动下载
          document.body.removeChild(tempLink) // 下载完毕删除a标签
          window.URL.revokeObjectURL(blobURL)
        }
      })
    },
    paytimechange(e) {
      console.info(e)
      this.paytimestr = e.join(',')
    },
    handleNodeClick(data) {
      this.$router.push({
        path: '/fj_order/list',
        query: { typeId: data.value }
      })
    },

    godetail(orderno) {
      this.$router.push({
        path: '/fj_order/edit',
        query: { orderno: orderno }
      })
    },
    deliveryexpress(id) {
      this.$router.push({
        path: '/fj_order/deyexpress',
        query: { id: id }
      })
    },
    editexpress(id) {
      this.$router.push({
        path: '/fj_order/deyexpress',
        query: { id: id }
      })
    },

    gorefund(orderno) {
      this.$router.push({
        path: '/fj_order/refundorder',
        query: { orderno: orderno }
      })
    },
    setStates(id, ispay, row) {
      let msg = '是否确认发货'
      if (ispay == 3) {
        msg = '是否确认完成'
      }

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          setStatesRequest({
            id: id,
            states: ispay
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              row.states = ispay
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },

    refundorder(orderno, ispay, row) {
      let msg = '是否确认退款'

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          refundorderRequest({
            orderno: orderno
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              this.onCurrentChange(1)
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    printorder(id) {
      this.$confirm('确定打印订单小票吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          printorder({
            id: id
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)

            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },


    ljerp(id) {
      this.$confirm('确定拦截该订单信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ljerp({
            id: id
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    setpay(id, row, ispay) {
      this.$confirm('是否确认该订单已经线下收款', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          setpayRequest({
            id: id,
            ispay,
            ispay
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              row.ispay = ispay
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    onpay(row) {
      this.$confirm('确认打款吗；直接到会员提现的微信账户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          paymentRequest({
            id: row.id
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg)
              row.status = 4
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },

    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    }
  }
}
</script> 

<style lang="less" scoped>

</style>